import React from 'react'
import { isBrowser } from '../utils'
import { youtubeLink, youtubeLinkUs } from '../data'
import { GlobalContext } from '../context/GlobalContext'
import { AppRegionEnum } from '../types/app-region.enum'

const Video = () => {
  const { region } = React.useContext(GlobalContext)

  return (
    isBrowser() &&
    window.location.replace(
      region === AppRegionEnum.Ru ? youtubeLink : youtubeLinkUs
    )
  )
}

export default Video
